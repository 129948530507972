import Axios from '../axios.js';

// 新建市场
export const createUser = (data) => {
    return Axios({
        url: `/api/admin/user`,
        method: 'post',
        // isFormData: true,
        data
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

// 获取市场列表
export const getUserList = (params) => {
    return Axios({
        url: `/api/admin/user`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 修改排序
export const editStatus = (params) => {
    return Axios({
        url: `/api/admin/market/status/${params.id}`,
        method: 'put',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 获取用户详情
export const getDetail = (params) => {
    return Axios({
        url: `/api/admin/user/${params.id}`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 修改市场
export const editUser = (id, data) => {
    return Axios({
        url: `/api/admin/user/${id}`,
        method: 'put',
        data
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

// 删除
export const deleteUser = (id) => {
    return Axios({
        url: `/api/admin/user/${id}`,
        method: 'delete'
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 在下面写就包含对象引入，以上属于可以解构的
export default {
    createUser,
    getUserList,
    editStatus,
    getDetail,
    editUser,
    deleteUser,
}
