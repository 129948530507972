<template>
  <section class="plr-sm">
    <m-header class="y-center ptb-xs">
      <span class="size-md bolder">账号编辑</span>
    </m-header>
    <div class="mian_cell">
      <m-card class="m-cardcell">
        <m-form :model="obj" labelWidth="70px">
          <m-form-item
              label="账号"
              type="input"
              :value.sync="obj.username"
              placeholder="请输入账号"
          >
          </m-form-item>
          <m-form-item
              label="密码"
              type="password"
              :value.sync="obj.password"
              placeholder="请输入密码"
          >
          </m-form-item>
          <m-form-item
              :disabled="source == 2"
              label="角色"
              type="select"
              :value.sync="roleId"
              :options="roleList"
              :optionKeys="['roleName', 'id']"
              placeholder="请选择角色"
          >
          </m-form-item>
          <m-form-item
              label="备注"
              type="input"
              :value.sync="obj.remark"
              placeholder="请输入备注"
          >
          </m-form-item>
        </m-form>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </m-card>
    </div>
  </section>
</template>

<script>
// import Obj from "@/classFactory/datemanagement/Teabrand.js";
import richTextEditor from "@/common/editor.vue";
import mCard from '@/common/m-card.vue'
import mFormItem from '@/common/m-form-item.vue'
import mForm from '@/common/m-form.vue'

import Market from '@/axios/api/market.js'
import User from '@/axios/api/user.js'
import Role from '@/axios/api/role.js'

export default {
  name: "user-edit",

  components: {
    richTextEditor,
    mCard,
    mFormItem,
    mForm
  },
  props: {
    source:{
      type: String,
      default: 1,
    },
  },
  data() {
    return {
      objId: null,
      obj: {
        username: '',
        password: '',
        remark: '',
        roleId: '',
      },
      roleId: '',
      roleList: []
    };
  },
  created() {
    let query = this.$route.query
    if (query.id) {
      this.objId = query.id
      this.getOne(query.id)
    }
    if(this.source == 2) {
      this.roleId = 6
    }
    this.getRoleList()
  },
  methods: {
    isValid(obj) {
      if (!obj.username) {
        this.$message.info("账号不能为空");
        return false;
      }
      if (!obj.password) {
        this.$message.info("密码不能为空");
        return false;
      }
      if (!obj.roleId) {
        this.$message.info("角色不能为空");
        return false;
      }
      return true;
    },
    // 保存
    onSubmit() {
      console.log('this', this.obj);
      this.obj.roleId = this.roleId
      if (this.isValid(this.obj)) {

        if (!this.objId) {
          // 新建
          User.createUser({...this.obj}).then(res => {
            if(this.source == 2) {
              //从新建党支部跳转来的
              this.obj = {}
              this.roleId = ''
              this.$emit('save')
            } else {
              this.$router.push("./list");
            }
          })
        } else {
          // 编辑
          User.editUser(this.objId, {...this.obj}).then(res => {
            if(this.source == 2) {
              //从新建党支部跳转来的
              this.obj = {}
              this.roleId = ''
              this.$emit('save')
            } else {
              this.$router.push("./list");
            }
          })
        }
      }
    },
    cancel() {
      if(this.source == 1) {
        this.$router.push('./list')
      } else {
        //从新建党支部跳转来的
        this.obj = {}
        this.roleId = ''
        this.$emit('cancel')
      }
    },
    deleteImg(index) {
      const that = this;
      that.$Modal.confirm({
        title: "提示",
        content: "是否删除，请确认",
        onOk: () => {
          // that.imgList.splice(index, 1);
          that.obj.imgUrl = "";
        },
      });
    },
    // uploadSuccess(res) {
    //   this.imgList.push({ url: res.data });
    // },
    uploadSubImg(res) {
      this.obj.coverImage = res.data;
      this.$forceUpdate();
    },
    async getOne(id) {
      let res = await User.getDetail({id})
      if (res.status == 1) {
        this.obj = res.data
        this.obj.status = this.obj.status == 1;
        this.roleId = res.data.roles[0].id
      }
    },
    async getRoleList() {
      let res = await Role.getRoleList({ page: 1, pageSize: 10000 })
      if (res.status == 1) {
        this.roleList = res.data;
      }
    },
    async checkName() {
      if (this.objId) {
        return;
      }
      let res = await Obj.prototype
          .checkOutTeaBrandRepeat({name: this.obj.name})
          .catch((err) => {
          });
      if (!res) {
        this.isExist = true;
      } else {
        this.isExist = false;
      }
    },
  },
};
</script>


<style scoped>
.photo-img-row {
  flex-wrap: wrap;
}

.photo-img {
  width: 200px;
  height: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
  object-fit: cover;
}

.photo-img__delete {
  font-size: 32px;
  position: absolute;
  right: 15px;
  top: 0;
  color: rgba(255, 0, 0, 0.8);
}
</style>

<style scoped>
.uploader {
  width: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploader.sub {
  width: 150px;
}

.uploader:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  line-height: 150px;
  text-align: center;
}

.uploader-icon.sub {
  width: 150px;
  line-height: 150px;
}


</style>
<!-- 我新增的 -->
<style scoped lang="less">
/deep/ .el-card__body .el-form-item__label {
  width: 70px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

}

.upload_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 88px;
  box-sizing: border-box;
  width: 88px;

  .text {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 8px;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #707070;
  }

  .subImg {
    width: 88px;
    height: 88px;
    vertical-align: middle;
  }
}

.mian_cell {
  display: flex;
  align-items: flex-start;

  .m-cardcell {
    background-color: #fff;
    flex: 1;
  }

  .right {
    width: 308px;
    height: 254px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    margin-left: 10px;
  }
}

</style>
