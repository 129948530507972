import Axios from '../axios.js';

// 获取市场列表
export const getRoleList = (params) => {
    return Axios({
        url: `/api/admin/user/role`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 在下面写就包含对象引入，以上属于可以解构的
export default {
    getRoleList
}
